import React from 'react';
import { Link } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';

const miniProjects = [
  { id: 1, title: 'Idea engine', description: 'Idea Engine is a user-centric platform aimed at guiding individuals through the process of crafting comprehensive business plans. Upon logging in, users are greeted with a dashboard where they can initiate or continue work on their business plan projects. The platform employs a series of interactive questionnaires, strategically designed to extract key insights and information necessary for a robust business plan. Users navigate through sections covering market analysis, product/service descriptions, marketing strategies, financial projections, and more. Access to a library of resources, including sample business plans, templates, and articles on best practices. Read More......', link: '/mini-project-1' },
  { id: 2, title: 'Development Engine', description: 'TEN AI Labs is an advanced platform offering a comprehensive suite of artificial intelligence (AI) tools and services. Whether you are a researcher, developer, or business professional, TEN AI Labs provides cutting-edge solutions to tackle a wide range of tasks and challenges. From natural language processing and computer vision to predictive analytics and machine learning, our platform harnesses the power of AI to empower users across industries. TEN AI Labs provides the resources, tools, and support you need to succeed in your endeavors. Join us on the journey to unlock the full potential of AI and shape the future of technology and society. Using this ai tools you can upgrate and upskills Yourslef Read More.......', link: '/mini-project-2' },
  { id: 3, title: 'Hiring Engine', description: ' At TenJobs, our mission is to revolutionize the way students prepare for their careers. We re dedicated to offering a comprehensive platform that equips students with not just theoretical knowledge, but also practical skills and real-world exposure. Imagine a world where every student has the opportunity to explore their interests, discover their passions, and seamlessly transition them into successful careers. With TenJobs, students have access to a diverse range of resources, including internships, mentorship programs, workshops, and networking opportunities. We believe that by providing students with relevant skills and practical experience, we can help them unlock their full potential and achieve their career goals. Read More.....', link: '/mini-project-3' },
  { id: 4, title: 'Marketing Engine', description: 'Marketing Engine empowers businesses of all sizes to achieve their marketing goals and drive sustainable growth. With a comprehensive suite of tools, advanced analytics, and actionable insights, Marketing Engine enables you to create compelling campaigns, engage your audience, and build lasting relationships with your customers. Take your marketing to the next level with Marketing Engine and unlock the full potential of your business. Read More......', link: '/mini-project-4' },
  { id: 5, title: 'Funding Engine', description: 'Funding Engine is more than just a platform it is a catalyst for entrepreneurial success. By providing entrepreneurs with the tools, resources, and connections they need to secure funding, Funding Engine empowers them to turn their dreams into reality. Whether you are a seasoned entrepreneur looking to scale your business or a budding startup in need of seed funding, Funding Engine is here to support you every step of the way. Join us and take your business to new heights with Funding Engine. Read More....', link: '/mini-project-5' },
  {
    id: 6,
    title: 'Growth Engine',
    description: 'Growth Engine is more than just a platform—it is a catalyst for entrepreneurial success. By providing entrepreneurs with the tools, resources, and connections they need to secure funding, Growth Engine empowers them to turn their dreams into reality. Whether you are a seasoned entrepreneur looking to scale your business or a budding startup in need of seed funding, Growth Engine is here to support you every step of the way. Join us and take your business to new heights with Growth Engine. Read More....',
    link: '/mini-project-6'
  },
  {
    id: 7,
    title: 'Newsin10',
    description: 'Newsin10 project focuses on delivering concise, daily news updates, making it easier for users to stay informed without spending too much time. It curates essential news stories, presenting them in a summarized format within 10 minutes. The goal is to provide quick, accurate, and relevant news coverage across various topics such as politics, technology, entertainment, and more, allowing busy individuals to stay up-to-date with the latest happenings every day. Read More....',
    link: '/mini-project-7'
  },
  {
    id: 8,
    title: 'Employee Management System',
    description: 'The Employee Management System is a comprehensive platform designed to streamline and simplify the process of managing employee data and operations within an organization. This system allows administrators and HR personnel to efficiently handle employee details, including personal information, job roles, performance metrics, and attendance records. It automates tasks such as payroll management, leave tracking, and employee performance evaluations, reducing manual work and minimizing errors. With features like role-based access, data analytics, and reporting tools, the Employee Management System enhances decision-making and improves overall organizational efficiency, fostering a better work environment for both management and employees. Read More....',
    link: '/mini-project-8'
  },
  {
    id: 9,
    title: 'TEN Mentor',
    description: 'TEN Mentor connects mentors with mentees based on shared interests, goals, and expertise, ensuring that each pairing is relevant and beneficial. Through one-on-one sessions, group workshops, and online resources, mentors share their knowledge and experiences, helping mentees develop skills, expand their networks, and achieve their professional objectives. By promoting a culture of continuous learning and personal growth, TEN Mentor empowers individuals to reach their full potential while fostering a supportive community of mentors and mentees. Read More....',
    link: '/mini-project-9'
  },
  {
    id: 10,
    title: 'AI Resume Builder',
    description: 'The AI Resume Builder project is an innovative tool designed to simplify the resume creation process using advanced artificial intelligence. This platform enables users to generate professional, tailored resumes quickly and efficiently, regardless of their experience level or industry. By leveraging AI algorithms, the tool analyzes job descriptions and suggests relevant skills, accomplishments, and formatting options that align with industry standards. Users can easily input their personal information, work history, and educational background, while the AI provides real-time feedback and recommendations to enhance their resumes. Additionally, the AI Resume Builder offers customizable templates, ensuring that users can create visually appealing and well-structured resumes that stand out to potential employers. Read More....',
    link: '/mini-project-10'
  },
  {
    id: 11,
    title: 'TEN AI Consulting Labs',
    description: "The TEN AI Consulting Lab is dedicated to accelerating the development and adoption of artificial intelligence across industries. Our mission is to empower businesses and organizations by providing cutting-edge AI solutions, tailored consulting, and strategic guidance. We help clients unlock the full potential of AI by addressing complex challenges and implementing innovative technologies that drive growth and efficiency. With a focus on research, collaboration, and real-world applications, the TEN AI Consulting Lab bridges the gap between AI theory and practical implementation. Our team of experts works closely with organizations to develop customized AI strategies, improve decision-making processes, and enhance operational performance. Whether it's through data analysis, machine learning, or automation, our goal is to make AI accessible and impactful, enabling businesses to stay ahead in a rapidly evolving technological landscape. Read More....",
    link: '/mini-project-11'
  },
];

const Home = () => {
  return (
    <div className="container mx-auto text-center py-10">
      <h1 className="text-4xl font-bold mb-10">God Engine</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {miniProjects.map(project => (
          <AnimatedCard key={project.id} project={project} />
        ))}
      </div>
    </div>
  );
};

const AnimatedCard = ({ project }) => {
  const props = useSpring({
    to: { opacity: 1, transform: 'scale(1)' },
    from: { opacity: 0, transform: 'scale(0.9)' },
    config: { mass: 1, tension: 170, friction: 26 },
  });

  return (
    <Link to={project.link} className="transform transition duration-500 hover:scale-105">
      <animated.div style={props} className="bg-white shadow-lg rounded-lg p-5 hover:bg-blue-50 min-h-[100%]">
        <h2 className="text-2xl font-bold mb-3">{project.title}</h2>
        <p className="text-gray-700">{project.description}</p>
      </animated.div>
    </Link>
  );
};

export default Home;
