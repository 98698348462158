import React from 'react';

const TEN_Mentor = () => {
  return (
    <div className="container mx-auto text-center py-10">
      <h1 className="text-4xl font-bold mb-10">TEN Mentor</h1>
      <div className="bg-gray-200 p-5 rounded-lg shadow-md">
        <h2 className="font-bold text-lg mb-2">Introduction</h2>
        <ul className="list-disc list-inside">
          <li><a href='https://mentor.entrepreneurshipnetwork.net/'>https://mentor.entrepreneurshipnetwork.net/</a></li>
          <li>TEN Mentor provides a platform that connects experienced professionals with aspiring individuals, fostering meaningful mentorship relationships tailored to specific interests and career goals.</li>
          <li>The project focuses on empowering mentees by offering valuable insights, knowledge sharing, and support from seasoned mentors, helping them navigate their career paths effectively.</li>
          <li>By promoting continuous learning and personal development, TEN Mentor fosters a supportive community where mentors and mentees can grow together and achieve their professional aspirations.</li>
        </ul>
        <h2 className="font-bold text-lg mb-2">Key Features</h2>
        <ul className="list-disc list-inside">
          <li>Mentor Matching System:
            <ul>
              <li>Utilizes algorithms to pair mentors and mentees based on shared interests, expertise, and goals.</li>
              <li>Ensures relevant and effective mentorship relationships for both parties.</li>
            </ul>
          </li>
          <li>One-on-One Mentoring Sessions:
            <ul>
              <li>Facilitates personalized, face-to-face or virtual meetings between mentors and mentees.</li>
              <li>Allows for tailored guidance, support, and feedback in a confidential setting.</li>
            </ul>
          </li>
          <li>Group Workshops and Seminars:
            <ul>
              <li>Organizes educational workshops led by mentors on various topics relevant to personal and professional development.</li>
              <li>Encourages networking and collaboration among participants.</li>
            </ul>
          </li>
          <li>Resource Library:
            <ul>
              <li>Provides access to a curated collection of articles, videos, and tools to support mentees in their learning journey.</li>
              <li>Offers resources on industry trends, skill development, and career advancement.</li>
            </ul>
          </li>
          <li>Progress Tracking and Feedback:
            <ul>
              <li>Includes tools for mentees to set goals and track their progress throughout the mentorship journey.</li>
              <li>Facilitates regular feedback sessions to ensure continuous improvement and accountability.</li>
            </ul>
          </li>
          <li>Community Forum:
            <ul>
              <li>Hosts an online platform for mentees and mentors to engage in discussions, share experiences, and ask questions.</li>
              <li>Fosters a sense of belonging and support within the mentorship community.</li>
            </ul>
          </li>
        </ul>
        <h2 className="font-bold text-lg mb-2">Benefits</h2>
        <ul className="list-disc list-inside">
          <li>Personalized Guidance: Mentees receive tailored support and advice from experienced professionals, helping them navigate their career paths and make informed decisions.</li>
          <li>Skill Development: Through one-on-one sessions and workshops, mentees can enhance their skills, gain new insights, and stay updated on industry trends, boosting their professional growth.</li>
          <li>Expanded Networking Opportunities: TEN Mentor facilitates connections between mentees and mentors, allowing them to build valuable professional networks that can open doors to new opportunities.</li>
          <li>Enhanced Confidence and Motivation: The encouragement and feedback from mentors help mentees build confidence in their abilities, fostering a positive mindset and motivation to achieve their career goals.</li>
        </ul>
        <h2 className="font-bold text-lg mb-2">Conclusion</h2>
        <p>In conclusion, the TEN Mentor project serves as a vital resource for individuals seeking to enhance their personal and professional development through mentorship. By connecting aspiring professionals with experienced mentors, it creates opportunities for personalized guidance, skill enhancement, and valuable networking. The supportive community fostered by TEN Mentor not only boosts confidence and motivation among mentees but also promotes a culture of continuous learning and growth. As a result, TEN Mentor empowers individuals to navigate their career paths successfully, making it an essential initiative for those aiming to reach their full potential in their respective fields.</p>
      </div>
    </div>
  );
};

export default TEN_Mentor;
