import React from 'react';

const News_in_10 = () => {
  return (
    <div className="container mx-auto text-center py-10">
      <h1 className="text-4xl font-bold mb-10">Newsin10</h1>
      <div className="bg-gray-200 p-5 rounded-lg shadow-md">
        <h2 className="font-bold text-lg mb-2">Introduction</h2>
        <ul className="list-disc list-inside">
          <li><a href='https://ten-news-vert.vercel.app/'>https://ten-news-vert.vercel.app/</a></li>
          <li>NewsIn10 offers quick, summarized daily news updates, allowing users to stay informed in just 10 minutes.</li>
          <li>It covers a variety of news categories, including politics, technology, entertainment, and more, ensuring diverse and relevant content.</li>
          <li>Designed for busy individuals, NewsIn10 helps users catch up on essential news without overwhelming them with lengthy articles.</li>
        </ul>
        <h2 className="font-bold text-lg mb-2">Key Features</h2>
        <ul className="list-disc list-inside">
          <li>Daily Summarized News:
            <ul>
              <li>Provides concise news summaries for quick consumption.</li>
              <li>Updates daily to ensure users get the most recent information.</li>
            </ul>
          </li>
          <li>Multi-Category Coverage:
            <ul>
              <li>Includes news from various categories like politics, sports, entertainment, technology, etc.</li>
              <li>Allows users to explore topics based on their interests.</li>
            </ul>
          </li>
          <li>User-Friendly Interface:
            <ul>
              <li>Simple, clean design for easy navigation and reading.</li>
              <li>Optimized for both desktop and mobile devices for accessibility on the go.</li>
            </ul>
          </li>
          <li>Personalized News Feed:
            <ul>
              <li>Customizable news feed based on user preferences and selected categories.</li>
              <li>Tailors content to user interests, improving engagement and relevance.</li>
            </ul>
          </li>
          <li>Breaking News Alerts:
            <ul>
              <li>Sends timely notifications for important breaking news.</li>
              <li>Keeps users informed about critical updates as they happen.</li>
            </ul>
          </li>
          <li>Offline Access:
            <ul>
              <li>Allows users to save articles and read them offline.</li>
              <li>Enhances convenience for users with limited internet access.</li>
            </ul>
          </li>
        </ul>
        <h2 className="font-bold text-lg mb-2">Benefits</h2>
        <ul className="list-disc list-inside">
          <li>Time-Efficient News Consumption: Users can stay informed with all essential news in just 10 minutes, saving time while staying updated on current events.</li>
          <li>Wide Topic Coverage: By offering news from various categories like politics, technology, and entertainment, users get a comprehensive view of what's happening globally.</li>
          <li>Personalized News Experience: Customizable feeds ensure users receive news tailored to their interests, improving relevance and engagement.</li>
          <li>Accessibility on the Go: With mobile optimization and offline reading options, users can access news anytime, anywhere, even without internet access.</li>
        </ul>
        <h2 className="font-bold text-lg mb-2">Conclusion</h2>
        <p>In conclusion, NewsIn10 offers an innovative solution for staying updated in today's fast-paced world. By delivering concise, diverse, and customizable news content, it enables users to efficiently consume relevant information without dedicating much time. Its user-friendly interface, personalized features, and offline access make it an essential tool for anyone looking to stay informed on the go. NewsIn10 is a perfect blend of convenience and comprehensive coverage, helping users keep up with the latest events quickly and effortlessly.</p>
      </div>
    </div>
  );
};

export default News_in_10;

