import React from 'react';

const FundingEngine = () => {
  return (
    <div className="container mx-auto text-center py-10">
      <h1 className="text-4xl font-bold mb-10">Funding Engine: Connecting Entrepreneurs with Investors</h1>
      <div className="bg-gray-200 p-5 rounded-lg shadow-md">
        <h2 className="font-bold text-lg mb-2">Introduction</h2>
        <ul className="list-disc list-inside">
        <li>Not Deployed Yet</li>
          <li>Funding Engine is a revolutionary platform designed to streamline the process of connecting entrepreneurs with potential investors.</li>
          <li>With Funding Engine, entrepreneurs gain access to a curated list of investors who are actively seeking investment opportunities.</li>
          <li>Our platform simplifies the funding process, providing entrepreneurs with the tools and resources they need to pitch their ideas effectively and secure the funding they require to bring their visions to life.</li>
        </ul>
        <h2 className="font-bold text-lg mb-2">Key Features</h2>
        <ul className="list-disc list-inside">
          <li>Investor Database:
            <ul>
              <li>Access a comprehensive database of investors from various industries and sectors.</li>
              <li>Filter investors based on criteria such as investment preferences, industry focus, and geographical location.</li>
            </ul>
          </li>
          <li>Pitch Creation and Management:
            <ul>
              <li>Create professional pitches using customizable templates and tools provided by Funding Engine.</li>
              <li>Manage and track pitch submissions, view investor feedback, and follow up on leads.</li>
            </ul>
          </li>
          <li>Matchmaking Algorithm:
            <ul>
              <li>Utilize our advanced matchmaking algorithm to connect with investors who align with your business goals and objectives.</li>
              <li>Receive personalized recommendations based on your profile and investment criteria.</li>
            </ul>
          </li>
          <li>Resource Library:
            <ul>
              <li>Access a wealth of resources, including articles, guides, and tutorials, to enhance your pitching skills and refine your business strategy.</li>
              <li>Learn from successful entrepreneurs and industry experts to improve your chances of securing funding.</li>
            </ul>
          </li>
          <li>Networking Opportunities:
            <ul>
              <li>Connect with other entrepreneurs, mentors, and industry professionals through networking events, webinars, and forums.</li>
              <li>Build valuable relationships and partnerships that can help propel your business forward.</li>
            </ul>
          </li>
          <li>Data Security and Privacy:
            <ul>
              <li>Rest assured knowing that your sensitive information is protected with state-of-the-art security measures.</li>
              <li>Maintain control over who can access your pitch materials and investment details.</li>
            </ul>
          </li>
        </ul>
        <h2 className="font-bold text-lg mb-2">Benefits</h2>
        <ul className="list-disc list-inside">
          <li>Access to Capital: Funding Engine provides entrepreneurs with access to a diverse pool of investors, increasing their chances of securing the funding they need to grow their businesses.</li>
          <li>Efficiency: By streamlining the investor search and pitching process, Funding Engine saves entrepreneurs valuable time and resources.</li>
          <li>Customization: Entrepreneurs can tailor their pitches to specific investors and investment criteria, increasing the likelihood of a successful funding round.</li>
          <li>Networking Opportunities: In addition to connecting with investors, entrepreneurs can network with other like-minded individuals and industry experts, fostering collaboration and innovation.</li>
        </ul>
        <h2 className="font-bold text-lg mb-2">Conclusion</h2>
        <p>Funding Engine is more than just a platform—it's a catalyst for entrepreneurial success. By providing entrepreneurs with the tools, resources, and connections they need to secure funding, Funding Engine empowers them to turn their dreams into reality. Whether you're a seasoned entrepreneur looking to scale your business or a budding startup in need of seed funding, Funding Engine is here to support you every step of the way. Join us and take your business to new heights with Funding Engine.</p>
      </div>
    </div>
  );
};

export default FundingEngine;
