import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import MiniProject1 from './miniProjects/MiniProject1';
import MiniProject2 from './miniProjects/MiniProject2';
import MiniProject3 from './miniProjects/MiniProject3';
import MiniProject4 from './miniProjects/MiniProject4';
import MiniProject5 from './miniProjects/MiniProject5';
import MiniProject6 from './miniProjects/MiniProject6';
import MiniProject7 from './miniProjects/MiniProject7';
import MiniProject8 from './miniProjects/MiniProject8';
import MiniProject9 from './miniProjects/MiniProject9';
import MiniProject10 from './miniProjects/MiniProject10';
import MiniProject11 from './miniProjects/MiniProject11';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mini-project-1" element={<MiniProject1 />} />
          <Route path="/mini-project-2" element={<MiniProject2 />} />
          <Route path="/mini-project-3" element={<MiniProject3 />} />
          <Route path="/mini-project-4" element={<MiniProject4 />} />
          <Route path="/mini-project-5" element={<MiniProject5 />} />
          <Route path="/mini-project-6" element={<MiniProject6 />} />
          <Route path="/mini-project-7" element={<MiniProject7 />} />
          <Route path="/mini-project-8" element={<MiniProject8 />} />
          <Route path="/mini-project-9" element={<MiniProject9 />} />
          <Route path="/mini-project-10" element={<MiniProject10 />} />
          <Route path="/mini-project-11" element={<MiniProject11 />} />
          {/* <Route path="/mini-project-6" element={<MiniProject6 />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
