import React from 'react';

const Employee_Management_System = () => {
  return (
    <div className="container mx-auto text-center py-10">
      <h1 className="text-4xl font-bold mb-10">Employee Management System</h1>
      <div className="bg-gray-200 p-5 rounded-lg shadow-md">
        <h2 className="font-bold text-lg mb-2">Introduction</h2>
        <ul className="list-disc list-inside">
          <li><a href='https://employeemanagement.entrepreneurshipnetwork.net/'>https://employeemanagement.entrepreneurshipnetwork.net/</a></li>
          <li>The Employee Management System centralizes employee information, making it easy to manage personal details, roles, and records.</li>
          <li>It automates essential HR tasks such as payroll, attendance tracking, and leave management, reducing manual effort and errors.</li>
          <li>With data analytics and reporting tools, the system aids in better decision-making, enhancing productivity and fostering a well-organized work environment..</li>
        </ul>
        <h2 className="font-bold text-lg mb-2">Key Features</h2>
        <ul className="list-disc list-inside">
          <li>Employee Information Management:
            <ul>
              <li>Stores and organizes comprehensive employee profiles, including personal, job, and contact information.</li>
              <li>Ensures easy access to updated employee records for HR and management.</li>
            </ul>
          </li>
          <li>Attendance and Leave Tracking:
            <ul>
              <li>Automates attendance tracking with features like clock-in/clock-out and leave request management.</li>
              <li>Provides real-time insights into employee attendance patterns and leave balances.</li>
            </ul>
          </li>
          <li>Payroll Management:
            <ul>
              <li>Automatically calculates salaries, deductions, bonuses, and generates payslips.</li>
              <li>Ensures accuracy in payroll processing, reducing errors and manual calculations.</li>
            </ul>
          </li>
          <li>Performance Management:
            <ul>
              <li>Tracks employee performance metrics and conducts regular performance reviews.</li>
              <li>Facilitates goal-setting and feedback, helping improve overall employee productivity.</li>
            </ul>
          </li>
          <li>Role-Based Access Control:
            <ul>
              <li>Provides secure, role-specific access to different system features based on job responsibilities.</li>
              <li>Ensures sensitive data is protected and only accessible by authorized personnel.</li>
            </ul>
          </li>
          <li>Analytics and Reporting:
            <ul>
              <li>Generates detailed reports on employee performance, attendance, and payroll.</li>
              <li>Provides data-driven insights to aid in strategic decision-making for HR and management.</li>
            </ul>
          </li>
        </ul>
        <h2 className="font-bold text-lg mb-2">Benefits</h2>
        <ul className="list-disc list-inside">
          <li>Increased Efficiency: Automating HR processes like payroll, attendance, and leave management reduces manual work, saving time and minimizing errors.</li>
          <li>Enhanced Data Organization: Centralized employee information ensures that records are easily accessible and up-to-date, improving operational efficiency.</li>
          <li>Improved Decision-Making: Real-time analytics and reporting provide valuable insights, helping management make informed decisions about employee performance and organizational needs.</li>
          <li>Better Employee Experience: By streamlining processes such as leave requests and performance reviews, the system fosters a more transparent and productive work environment for employees.</li>
        </ul>
        <h2 className="font-bold text-lg mb-2">Conclusion</h2>
        <p>In conclusion, the Employee Management System is a vital tool for modern organizations seeking to optimize their HR processes. By centralizing employee data, automating routine tasks, and providing valuable insights through analytics, it enhances overall operational efficiency. This system not only improves accuracy in payroll and attendance tracking but also facilitates better decision-making and fosters a more transparent, organized, and productive work environment. The Employee Management System is a key asset for businesses aiming to streamline their workforce management and support their employees effectively.</p>
      </div>
    </div>
  );
};

export default Employee_Management_System;
