import React from 'react';

const GrowthEngine = () => {
  return (
    <div className="container mx-auto text-center py-10">
      <h1 className="text-4xl font-bold mb-10">Growth Engine: Empowering Entrepreneurs to Scale</h1>
      <div className="bg-gray-200 p-5 rounded-lg shadow-md">
        <h2 className="font-bold text-lg mb-2">Introduction</h2>
        <ul className="list-disc list-inside">
          <li>Not Deployed Yet</li>
          <li>Growth Engine is an innovative platform designed to facilitate the scaling process for entrepreneurs by connecting them with strategic investors.</li>
          <li>With Growth Engine, entrepreneurs gain access to a diverse network of investors who are actively seeking high-potential investment opportunities.</li>
          <li>Our platform streamlines the funding journey, offering entrepreneurs the tools and resources they need to present their business effectively and secure the investment necessary for expansion.</li>
        </ul>
        <h2 className="font-bold text-lg mb-2">Key Features</h2>
        <ul className="list-disc list-inside">
          <li>Investor Network:
            <ul>
              <li>Access a robust network of investors across various industries and sectors.</li>
              <li>Filter investors based on preferences such as investment amount, industry focus, and geographical location.</li>
            </ul>
          </li>
          <li>Pitch Development and Management:
            <ul>
              <li>Create compelling pitches using customizable templates and tools provided by Growth Engine.</li>
              <li>Manage and track pitch submissions, receive investor feedback, and follow up on potential leads.</li>
            </ul>
          </li>
          <li>Advanced Matchmaking:
            <ul>
              <li>Use our sophisticated matchmaking algorithm to connect with investors whose interests align with your business goals.</li>
              <li>Receive personalized investor recommendations based on your profile and funding requirements.</li>
            </ul>
          </li>
          <li>Resource Hub:
            <ul>
              <li>Access a comprehensive library of resources, including articles, guides, and tutorials, to refine your pitching skills and business strategy.</li>
              <li>Learn from successful entrepreneurs and industry experts to enhance your chances of securing investment.</li>
            </ul>
          </li>
          <li>Networking Events:
            <ul>
              <li>Engage with other entrepreneurs, mentors, and industry professionals through networking events, webinars, and forums.</li>
              <li>Build valuable connections and partnerships to accelerate your business growth.</li>
            </ul>
          </li>
          <li>Data Protection and Privacy:
            <ul>
              <li>Be confident that your sensitive information is safeguarded with cutting-edge security measures.</li>
              <li>Control who can access your pitch materials and investment details.</li>
            </ul>
          </li>
        </ul>
        <h2 className="font-bold text-lg mb-2">Benefits</h2>
        <ul className="list-disc list-inside">
          <li>Access to Capital: Growth Engine provides entrepreneurs with access to a wide pool of investors, increasing their chances of obtaining the necessary funding to grow their businesses.</li>
          <li>Efficiency: By simplifying the investor search and pitching process, Growth Engine saves entrepreneurs valuable time and resources.</li>
          <li>Customization: Entrepreneurs can tailor their pitches to specific investors and investment criteria, improving the likelihood of successful funding rounds.</li>
          <li>Networking: In addition to connecting with investors, entrepreneurs can network with like-minded individuals and industry experts, fostering collaboration and innovation.</li>
        </ul>
        <h2 className="font-bold text-lg mb-2">Conclusion</h2>
        <p>Growth Engine is more than just a platform—it's a partner in your entrepreneurial journey. By providing entrepreneurs with the tools, resources, and connections they need to secure investment, Growth Engine empowers them to scale their businesses and achieve their dreams. Whether you're an established entrepreneur looking to expand or a startup in need of initial funding, Growth Engine is here to support you every step of the way. Join us and take your business to new heights with Growth Engine.</p>
      </div>
    </div>
  );
};

export default GrowthEngine;

