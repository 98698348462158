import React from 'react';

const TENJobs = () => {
  return (
    <div className="container mx-auto text-center py-10">
      <h1 className="text-4xl font-bold mb-10">TEN Jobs</h1>
      <div className="bg-gray-200 p-5 rounded-lg shadow-md">
        <h2 className="font-bold text-lg mb-2">Overview</h2>
        <ul className="list-disc list-inside">
          <li><b>Click Here - </b>https://jobs.entrepreneurshipnetwork.net/</li>
          <li>At TEN Jobs, our mission is to revolutionize the way students prepare for their careers. We're dedicated to offering a comprehensive platform that equips students with not just theoretical knowledge, but also practical skills and real-world exposure.</li>
          <li>Imagine a world where every student has the opportunity to explore their interests, discover their passions, and seamlessly transition them into successful careers.</li>
          <li>With TEN Jobs, students have access to a diverse range of resources, including internships, job opportunities, mentorship programs, workshops, and networking opportunities.</li>
        </ul>
        <h2 className="font-bold text-lg mb-2">Key Features</h2>
        <ul className="list-disc list-inside">
          <li>Student Portal:
            <ul>
              <li>Students can create accounts, upload resumes, and browse through a wide range of internship and job opportunities.</li>
              <li>Advanced search filters allow students to narrow down their options based on industry, location, duration, and more.</li>
              <li>Application tracking feature enables students to monitor the status of their applications and receive updates on their progress.</li>
            </ul>
          </li>
          <li>Company Portal:
            <ul>
              <li>Companies can register accounts, post internship and job listings, and manage candidate applications.</li>
              <li>Customizable job postings with detailed descriptions, requirements, and application deadlines.</li>
              <li>Applicant management system to review resumes, schedule interviews, and communicate with candidates.</li>
            </ul>
          </li>
          <li>Dual Authentication System:
            <ul>
              <li>Two separate login forms for students and admins ensure secure access and authentication.</li>
              <li>User-friendly interfaces for streamlined login and account management.</li>
            </ul>
          </li>
          <li>Job and Internship Listings:
            <ul>
              <li>Comprehensive database of internship and job opportunities across various industries and sectors.</li>
              <li>Regular updates and notifications on new listings matching users' preferences.</li>
            </ul>
          </li>
          <li>Application Management:
            <ul>
              <li>Automated application processing to streamline the recruitment process for both students and companies.</li>
              <li>Real-time notifications and alerts to keep users informed about the status of their applications.</li>
            </ul>
          </li>
          <li>Feedback and Ratings:
            <ul>
              <li>Feedback mechanism for students to rate their internship and job experiences and provide valuable insights to companies.</li>
              <li>Companies can also rate students based on their performance during internships and jobs, facilitating mutual feedback and improvement.</li>
            </ul>
          </li>
        </ul>
        <h2 className="font-bold text-lg mb-2">User Experience</h2>
        <ul className="list-disc list-inside">
          <li>Student Experience:
            <ul>
              <li>Students can easily create profiles, upload resumes, and search for internship and job opportunities tailored to their interests and qualifications.</li>
              <li>Intuitive dashboard providing a centralized view of application statuses, upcoming deadlines, and recommended listings.</li>
              <li>Seamless application process with one-click submission and tracking features.</li>
            </ul>
          </li>
          <li>Company Experience:
            <ul>
              <li>Companies can effortlessly create accounts, post job listings, and manage candidate applications through a user-friendly interface.</li>
              <li>Advanced search and filtering options to find the most suitable candidates for internship and job positions.</li>
              <li>Centralized dashboard for tracking application statuses, scheduling interviews, and communicating with candidates.</li>
            </ul>
          </li>
        </ul>
        <h2 className="font-bold text-lg mb-2">Benefits</h2>
        <ul className="list-disc list-inside">
          <li>Efficiency: TEN Jobs streamlines the internship and job placement process, saving time and effort for both students and companies.</li>
          <li>Accessibility: The platform provides a centralized hub for students to discover job and internship opportunities and for companies to recruit talented individuals, making the process accessible to a wider audience.</li>
          <li>Transparency: Real-time updates and notifications ensure transparency throughout the recruitment process, keeping all parties informed about the status of applications and opportunities.</li>
          <li>Feedback and Improvement: The feedback mechanism allows students and companies to provide constructive feedback, facilitating continuous improvement and enhancing the overall job and internship experience.</li>
        </ul>
        <h2 className="font-bold text-lg mb-2">Conclusion</h2>
        <p>TEN Jobs is a transformative platform that revolutionizes the way students find jobs and internships and how companies recruit talent. By providing a user-friendly interface, advanced features, and seamless functionality, the platform fosters collaboration and connection between students and companies, ultimately driving mutual success and growth. Join us on TEN Jobs and embark on a journey towards fulfilling job and internship experiences and rewarding career opportunities.</p>
      </div>
    </div>
  );
};

export default TENJobs;
