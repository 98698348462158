import React from 'react';

const MiniProject1 = () => {
  return (
    <div className="container mx-auto text-center py-10">
      <h1 className="text-4xl font-bold mb-10">Idea Engine</h1>
      <div className="bg-gray-200 p-5 rounded-lg shadow-md">
        <p className="font-bold">Welcome to Idea Engine!</p>
        <h1 className="font-bold text-lg mb-2">Overview</h1>
        <ul className="list-disc list-inside">
            <li>Link of the project : <a href="https://ideaengine.entrepreneurshipnetwork.net/">https://ideaengine.entrepreneurshipnetwork.net/</a></li>
          <li>Idea Engine is an innovative platform designed to help aspiring entrepreneurs and business enthusiasts turn their ideas into actionable business plans. By guiding users through a series of structured questions, the platform helps in formulating a comprehensive business plan that is tailored to their specific vision and goals. With a user-friendly interface and interactive features, Idea Engine makes the process of business planning intuitive, accessible, and efficient.</li>
        </ul>
        <h1 className="font-bold text-lg mb-2">Key Features</h1>
        <ul className="list-disc list-inside">
          <li className="font-bold">User Authentication:</li>
          <ul>
            <li>Secure login and registration process.</li>
            <li>User profile management.</li>
          </ul>
          <li className="font-bold">Interactive Questionnaires:</li>
          <ul>
            <li>A series of thoughtfully designed questions to guide users through the business planning process.</li>
            <li>Sections covering key aspects of a business plan, including market analysis, product/service description, marketing strategy, financial projections, and more.</li>
          </ul>
          <li className="font-bold">Business Plan Creation:</li>
          <ul>
            <li>Automated generation of a business plan document based on user responses.</li>
            <li>Editable and downloadable business plan in various formats (PDF, DOCX).</li>
          </ul>
          <li className="font-bold">Progress Tracking:</li>
          <ul>
            <li>Save progress feature to allow users to complete their plans at their own pace.</li>
            <li>Visual indicators showing completion status of each section.</li>
          </ul>
          <li className="font-bold">Collaboration Tools:</li>
          <ul>
            <li>Option to invite collaborators to contribute to the business plan.</li>
            <li>Real-time editing and comments.</li>
          </ul>
          <li className="font-bold">Resources and Guidance:</li>
          <ul>
            <li>Access to a library of resources, including sample business plans, templates, and articles on best practices.</li>
            <li>Tips and hints provided at each step of the questionnaire to assist users in crafting detailed and effective responses.</li>
          </ul>
        </ul>
        <h1 className="font-bold text-lg mb-2">User Journey</h1>
        <ul className="list-disc list-inside">
          <li className="font-bold">Registration and Login:</li>
          <ul>
            <li>Users start by creating an account or logging in to an existing account.</li>
            <li>A secure authentication process ensures the privacy and safety of user data.</li>
          </ul>
          <li className="font-bold">Dashboard:</li>
          <ul>
            <li>Upon logging in, users are directed to their personal dashboard where they can start a new business plan or continue working on an existing one.</li>
            <li>The dashboard provides an overview of all current and past projects.</li>
          </ul>
          <li className="font-bold">Business Plan Creation:</li>
          <ul>
            <li>Users answer a series of structured questions, divided into different sections.</li>
            <li>Each question is designed to elicit detailed information about various aspects of the business, from market analysis to financial planning.</li>
          </ul>
          <li className="font-bold">Review and Edit:</li>
          <ul>
            <li>Users can review and edit their responses to ensure accuracy and completeness.</li>
            <li>The platform provides instant feedback and suggestions to enhance the quality of the responses.</li>
          </ul>
          <li className="font-bold">Collaboration:</li>
          <ul>
            <li>Users can invite partners or team members to collaborate on the business plan.</li>
            <li>Real-time editing and commenting features facilitate smooth teamwork.</li>
          </ul>
          <li className="font-bold">Finalization and Download:</li>
          <ul>
            <li>Once all sections are completed, users can generate a professional business plan document.</li>
            <li>The final business plan can be downloaded in multiple formats for presentation to stakeholders, investors, or for personal use.</li>
          </ul>
        </ul>
        <h1 className="font-bold text-lg mb-2">Benefits</h1>
        <ul className="list-disc list-inside">
          <li><strong>Accessibility:</strong> Allows anyone with an idea to develop a comprehensive business plan without needing expert knowledge.</li>
          <li><strong>Efficiency:</strong> Streamlines the business planning process, saving time and effort.</li>
          <li><strong>Collaboration:</strong> Facilitates teamwork and collective input, enhancing the quality of the business plan.</li>
          <li><strong>Professionalism:</strong> Produces polished and professional business plans ready for presentation.</li>
        </ul>
        <h1 className="font-bold text-lg mb-2">Conclusion</h1>
        <p>Idea Engine is a powerful tool designed to democratize the process of business planning. By providing a structured and interactive platform, it empowers users to transform their ideas into viable business plans, paving the way for entrepreneurial success. Whether you are a seasoned entrepreneur or a novice with a groundbreaking idea, Idea Engine provides the resources and guidance needed to bring your vision to life.</p>
      </div>
    </div>
  );
};

export default MiniProject1;
